
























































import Vue from 'vue';
import Vector3 from '../components/Vector3.vue';
import VV from '../components/VV.vue';
import VVPlot from '../components/VVPlot.vue';
import ColorVector from '../components/ColorVector.vue';
import StraightLine from '../components/StraightLine.vue';
import Camera from '../components/Camera.vue';
import { Geo } from '@/models/geo';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';

export default defineComponent({
  components: {
    Vector3, VV, VVPlot, ColorVector, StraightLine, Camera,
  },

  setup(
    context: SetupContext,
  ) {
    const v1: Geo.Vector3 = { x: 1, y: 0, z: 0, };
    const v2: Geo.Vector3 = { x: 0, y: 1, z: 0, };
    const cv: Geo.Vector3 = { x: 255, y: 255, z: 255, };
    const sline: Geo.StraightLine = reactive({
      origin: { x: 0, y: 0, z: 0, },
      direction: { x: 0, y: 0, z: 1, },
    });
    const camera: Geo.Camera = reactive({
      pov: { x: 0, y: 0, z: 0, },
      direction: { x: 0, y: 0, z: 1, },
      fov: 90,
    });
    const screen: Geo.VirtualScreen = reactive({
      width: 2.0,
      aspect_ratio: 16 / 9,
    });

    const viewData = reactive({
      vector_1: v1,
      vector_2: v2,
      normalize_1: false,
      normalize_2: false,
      cv: cv,
      sline: sline,
      camera,
      screen,
    });

    const info_v1 = computed(() => Geo.vinfo(v1));
    const info_v2 = computed(() => Geo.vinfo(v2));

    return {
      viewData,
      info_v1,
      info_v2,
      monitor: (e: any) => console.log(viewData.cv.x, viewData.cv.y, viewData.cv.z),
    };
  },
});
