import { computed } from '@vue/composition-api';
import _ from 'lodash';
import { sprintf } from 'sprintf-js'

export namespace Geo {
  export const Epsilon = 1e-5;

  export interface Vector3 {
    x: number;
    y: number;
    z: number;
  }

  export function add(v1: Vector3, v2: Vector3) {
    return { x: v1.x + v2.x, y: v1.y + v2.y, z: v1.z + v2.z };
  }

  export function sub(v1: Vector3, v2: Vector3) {
    return { x: v1.x - v2.x, y: v1.y - v2.y, z: v1.z - v2.z };
  }

  export function multiply(v: Vector3, d: number) {
    return { x: v.x * d, y: v.y * d, z: v.z * d };
  }

  export function largestComponent(v: Vector3): number {
    return Math.max(Math.abs(v.x), Math.abs(v.y), Math.abs(v.z));
  }

  export function unit_vector(): Vector3 {
    return { x: 0, y: 0, z: 1 };
  }

  export function dot(v1: Vector3, v2: Vector3): number {
    return (v1.x * v2.x + v1.y * v2.y + v1.z * v2.z);
  }

  export function cross(v1: Vector3, v2: Vector3): Vector3 {
    return {
      x: v1.y * v2.z - v1.z * v2.y,
      y: v1.z * v2.x - v1.x * v2.z,
      z: v1.x * v2.y - v1.y * v2.x,
    };
  }

  export function radian2degree(rad: number) {
    return rad / Math.PI * 180;
  }

  export function degree2radian(deg: number) {
    return deg / 180 * Math.PI;
  }

  export function norm(v: Vector3) {
    return Math.sqrt(dot(v, v));
  }

  export function normalize(v: Vector3) {
    const n = Math.sqrt(dot(v, v)) || 1;
    return {
      x: v.x / n,
      y: v.y / n,
      z: v.z / n,
    };
  }

  export function isNormalized(v: Vector3, epsilon = Epsilon) {
    return epsilon > Math.abs(norm(v) - 1);
  }

  export function isFinite(v: Vector3) {
    return [v.x, v.y, v.z].every(t => _.isFinite(t))
  }

  export function refine(v: Vector3) {
    v.x = parseFloat(v.x as any);
    v.y = parseFloat(v.y as any);
    v.z = parseFloat(v.z as any);
  }

  export interface Vector3Info {
    vector: Vector3;
    norm: number;
    normal: Vector3;
    angle_xaxis: number;
    angle_yaxis: number;
    angle_zaxis: number;
  }

  export function vinfo(v: Vector3): Vector3Info {
    const cv = { x: parseFloat(v.x as any), y: parseFloat(v.y as any), z: parseFloat(v.z as any) };
    const normal = normalize(v);
    return {
      vector: cv,
      norm: norm(cv),
      normal,
      angle_xaxis: radian2degree(Math.acos(dot(normal, { x: 1, y: 0, z: 0 }))),
      angle_yaxis: radian2degree(Math.acos(dot(normal, { x: 0, y: 1, z: 0 }))),
      angle_zaxis: radian2degree(Math.acos(dot(normal, { x: 0, y: 0, z: 1 }))),
    };
  }

  export interface StraightLine {
    origin: Vector3;
    direction: Vector3;
  };

  export interface StraightLineInfo {
    sline: StraightLine;
    direction_info: Vector3Info;
    t_on_xy_plain: number;
    t_on_yz_plain: number;
    t_on_zx_plain: number;
    p_on_xy_plain: Vector3;
    p_on_yz_plain: Vector3;
    p_on_zx_plain: Vector3;
  }

  export function slinfo(sline: StraightLine): StraightLineInfo {
    // (origin + t * direction).z = 0
    // t = - origin.z / direction.z
    const t_on_xy_plain = - sline.origin.z / sline.direction.z;
    const t_on_yz_plain = - sline.origin.x / sline.direction.x;
    const t_on_zx_plain = - sline.origin.y / sline.direction.y;
    return {
      sline,
      direction_info: vinfo(sline.direction),
      t_on_xy_plain,
      p_on_xy_plain: add(sline.origin, multiply(sline.direction, t_on_xy_plain)),
      t_on_yz_plain,
      p_on_yz_plain: add(sline.origin, multiply(sline.direction, t_on_yz_plain)),
      t_on_zx_plain,
      p_on_zx_plain: add(sline.origin, multiply(sline.direction, t_on_zx_plain)),
    };
  }

  export type VirtualScreen = {
    width: number;
    aspect_ratio: number;
  };

  export interface Camera {
    pov: Vector3;
    direction: Vector3;
    fov: number;
  };

  export interface Plain {
    ref_point: Vector3;
    normal: Vector3;
  };

  function rot_x(v: Vector3, radian: number) {
    return {
      x: v.x,
      y: v.y * Math.cos(radian) - v.z * Math.sin(radian),
      z: v.y * Math.sin(radian) + v.z * Math.cos(radian),
    };
  }

  function rot_y(v: Vector3, radian: number) {
    return {
      x: v.z * Math.sin(radian) + v.x * Math.cos(radian),
      y: v.y,
      z: v.z * Math.cos(radian) - v.x * Math.sin(radian),
    };
  }

  function rot_z(v: Vector3, radian: number) {
    return {
      x: v.x * Math.cos(radian) - v.y * Math.sin(radian),
      y: v.x * Math.sin(radian) + v.y * Math.cos(radian),
      z: v.z,
    };
  }

  function x_angle(v: Vector3) {
    return Math.atan2(v.y, v.z)
  }

  function y_angle(v: Vector3) {
    return Math.atan2(v.x, v.z)
  }

  function z_angle(v: Vector3) {
    return Math.atan2(v.y, v.x)
  }

  function azimuth(v: Vector3) {
    // if (v.x === 0 && v.z === 0) {
    //   return Math.atan2(0, 1);
    // }
    return Math.atan2(v.x, v.z);
  }

  function elevation(v: Vector3) {
    return Math.atan2(v.y, Math.sqrt(v.x * v.x + v.z * v.z));
  }

  function rot_around_axis(r: Vector3, axis: Vector3, angle: number) {
    return add(
      add(
        multiply(r, Math.cos(angle)),
        multiply(
          axis,
          dot(axis, r) * (1 - Math.cos(angle))
        )
      ),
      multiply(
        cross(axis, r),
        Math.sin(angle)
      )
    );
  }

  function orient(v: Vector3, orient: Vector3) {
    const az_axis: Vector3 = { x:0,  y: 1, z: 0 };
    const el_axis: Vector3 = { x:-1, y: 0, z: 0 };
    const az = azimuth(orient);
    const el = elevation(orient);
    const v2 = rot_around_axis(v, az_axis, az);
    const el_axis2 = rot_around_axis(el_axis, az_axis, az);
    return rot_around_axis(v2, el_axis2, el);
  }

  export interface CameraInfo {
    screen: VirtualScreen;
    camera: Camera;
    screen_height: number;
    focal_length: number;
    fov_vertical: number;
    screen_center: Vector3;

    ray_at_vp_north: Vector3;
    ray_at_vp_south: Vector3;
    ray_at_vp_west: Vector3;
    ray_at_vp_east: Vector3;
    vp_topleft: Vector3;
    vp_topright: Vector3;
    vp_bottomleft: Vector3;
    vp_bottomright: Vector3;
    fval: number;
    azimuth: number;
    elevation: number;
  };

  export function camera_info(camera: Camera, vscreen: VirtualScreen): CameraInfo {
    const fov_radian = camera.fov * Math.PI / 180;
    const screen_height = vscreen.width / vscreen.aspect_ratio;
    const focal_length = vscreen.width / (2 * Math.tan(fov_radian / 2));
    const fov_vertical = 2 * Math.atan2(screen_height / 2, focal_length) * 180 / Math.PI;
    const screen_center = add(camera.pov, multiply(camera.direction, focal_length));
    const az = azimuth(camera.direction);
    const el = elevation(camera.direction);
    const u_up: Vector3 = orient({ x: 0, y: screen_height / 2, z: 0 }, camera.direction);
    const u_left: Vector3 = orient({ x: vscreen.width / 2, y: 0, z: 0 }, camera.direction);
    const fval = focal_length / vscreen.width;
    const ray_at_vp_east = add(screen_center, u_left);
    const ray_at_vp_west = sub(screen_center, u_left);
    const ray_at_vp_north = add(screen_center, u_up);
    const ray_at_vp_south = sub(screen_center, u_up);
  return {
      camera,
      screen: vscreen,
      screen_height,
      focal_length,
      fov_vertical,
      screen_center,
      ray_at_vp_west,
      ray_at_vp_east,
      ray_at_vp_north,
      ray_at_vp_south,
      vp_topleft: add(ray_at_vp_west, u_up),
      vp_topright: add(ray_at_vp_east, u_up),
      vp_bottomleft: sub(ray_at_vp_west, u_up),
      vp_bottomright: sub(ray_at_vp_east, u_up),
      fval,
      azimuth: az * 180 / Math.PI,
      elevation: el * 180 / Math.PI,
    };
  };

  export interface Sphere {
    center: Vector3;
    radius: number;
  };

  export type Vector2 = {
    x: number;
    y: number;
  };

  export function add2(v1: Vector2, v2: Vector2) {
    return { x: v1.x + v2.x, y: v1.y + v2.y };
  }

  export function sub2(v1: Vector2, v2: Vector2) {
    return { x: v1.x - v2.x, y: v1.y - v2.y };
  }

  export function multiply2(v: Vector2, m: number) {
    return { x: v.x * m, y: v.y * m };
  }

  export function project_xy(v3: Vector3): Vector2 {
    return { x: v3.x, y: v3.y };
  }

  export function project_yz(v3: Vector3): Vector2 {
    return { x: v3.y, y: v3.z };
  }

  export function project_zx(v3: Vector3): Vector2 {
    return { x: v3.z, y: v3.x };
  }
}

export namespace Format {
  export function number_str(val: number, fmt="%.5f") {
    return sprintf(fmt, val);
  }

  export function vector_str(vector: Geo.Vector3, fmt="(%.5f, %.5f, %.5f)") {
    return sprintf(fmt, vector.x, vector.y, vector.z);
  }
}