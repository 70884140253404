






































































































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import VectorField from '@/components/lv1/VectorField.vue'
import VectorProjector from '@/components/lv1/VectorProjector.vue'
import CopyButton from '@/components/CopyButton.vue'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components: {
    VectorField, VectorProjector, CopyButton,
  },

  props: {
    camera: {
      type: Object as PropType<Geo.Camera>,
      required: true,
    },
    screen: {
      type: Object as PropType<Geo.VirtualScreen>,
      required: true,
    },
  },

  setup(prop: {
    camera: Geo.Camera;
    screen: Geo.VirtualScreen;
  }, context: SetupContext) {

    const viewData = {
      raw_direction: { ...prop.camera.direction },
    };

    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    const listeners = {
      updatedRawDir: (e: any) => {
        Geo.refine(viewData.raw_direction);
        if (!Geo.isFinite({ ...viewData.raw_direction })) { return; }
        prop.camera.direction = Geo.normalize(viewData.raw_direction);
      },
      updatedFOV: (e: any) => {
        const fov = Math.min(180, Math.max(0, parseFloat(prop.camera.fov as any)));
        prop.camera.fov = fov;
      },
    };

    const camera_info = computed(() => {
      Geo.refine(prop.camera.pov);
      return Geo.camera_info(prop.camera, prop.screen);
    });
    const vp_length = computed(() => {
      const vpw = Geo.norm(Geo.sub(camera_info.value.vp_topright, camera_info.value.vp_topleft));
      const vph = Geo.norm(Geo.sub(camera_info.value.vp_topleft, camera_info.value.vp_bottomleft));
      return Math.max(vpw, vph) * 1.2;
    });
    const scale_factor = computed(() => svg_size / vp_length.value);
    const svg_size = 120;
    const viewParams = {
      svg_size,
      scale_factor,
      viewBox: computed(() => {
        return `${-svg_size/2} ${-svg_size/2} ${svg_size} ${svg_size}`;
      }),
      scaled_vp_topleft: computed(() => {
        return Geo.multiply(camera_info.value.vp_topleft, scale_factor.value);
      }),
      scaled_vp_topright: computed(() => {
        return Geo.multiply(camera_info.value.vp_topright, scale_factor.value);
      }),
      scaled_vp_bottomleft: computed(() => {
        return Geo.multiply(camera_info.value.vp_bottomleft, scale_factor.value);
      }),
      scaled_vp_bottomright: computed(() => {
        return Geo.multiply(camera_info.value.vp_bottomright, scale_factor.value);
      }),
    };
    const projected = {
      vxy: computed(() => {
        return {
          camera_origin: Geo.project_xy(prop.camera.pov),
          camera_direction: Geo.project_xy(prop.camera.direction),
          viewport_topleft: Geo.project_xy(camera_info.value.vp_topleft),
          viewport_topright: Geo.project_xy(camera_info.value.vp_topright),
          viewport_bottomleft: Geo.project_xy(camera_info.value.vp_bottomleft),
          viewport_bottomright: Geo.project_xy(camera_info.value.vp_bottomright),
        }
      }),
      vyz: computed(() => {
        return {
          camera_origin: Geo.project_yz(prop.camera.pov),
          camera_direction: Geo.project_yz(prop.camera.direction),
          viewport_topleft: Geo.project_yz(camera_info.value.vp_topleft),
          viewport_topright: Geo.project_yz(camera_info.value.vp_topright),
          viewport_bottomleft: Geo.project_yz(camera_info.value.vp_bottomleft),
          viewport_bottomright: Geo.project_yz(camera_info.value.vp_bottomright),
        }
      }),
      vzx: computed(() => {
        return {
          camera_origin: Geo.project_zx(prop.camera.pov),
          camera_direction: Geo.project_zx(prop.camera.direction),
          viewport_topleft: Geo.project_zx(camera_info.value.vp_topleft),
          viewport_topright: Geo.project_zx(camera_info.value.vp_topright),
          viewport_bottomleft: Geo.project_zx(camera_info.value.vp_bottomleft),
          viewport_bottomright: Geo.project_zx(camera_info.value.vp_bottomright),
        }
      }),
    };

    return {
      viewData,
      format,
      listeners,
      camera_info,
      viewParams,
      projected,
    };
  },
});

