










































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import VectorField from '@/components/lv1/VectorField.vue'
import CopyButton from '@/components/CopyButton.vue'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components: {
    VectorField, CopyButton,
  },

  props: {
    value: {
      type: Object as PropType<Geo.Vector3>,
      required: true,
    },
    normalize: {
      type: Boolean,
    },
  },

  setup(prop: {
      value: Geo.Vector3;
      normalize: boolean;
  }, context: SetupContext) {
    const vector_info = computed(() => Geo.vinfo(prop.value));

    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    const emitNormalize = () => {
      context.emit("switchNormalize", { normalize: !prop.normalize });
    };

    return {
      vector_info,
      format,
      emitNormalize,
      handleInput: (e: any) => context.emit("input", e),
    };
  },
});

