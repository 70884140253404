
















import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import CopyButton from '@/components/CopyButton.vue'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components: {
    CopyButton,
  },

  props: {
    value: {
      type: Object as PropType<Geo.Vector3>,
      required: true,
    },
  },

  setup(prop: {
    value: Geo.Vector3;
  }, context: SetupContext) {
    const vector_info = computed(() => Geo.vinfo(prop.value));
    const cv: Geo.Vector3 = { x: prop.value.x, y: prop.value.y, z: prop.value.z, };
    const viewParam = reactive({
      color_text: "#000000",
      cv,
    });

    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    const validateColorScalarRange = (v: number) => {
      return 0 <= v && v <= 255;
    };
    const validateColorVectorRange = (v: Geo.Vector3) => {
      return validateColorScalarRange(v.x) && validateColorScalarRange(v.y) && validateColorScalarRange(v.z)
    };

    const assimilateVectorStr = (e: string) => {
      const str = e;
      const m = str.match(/^\s*([+-]?\d+(?:\.\d*)?)\s*,\s*([+-]?\d+(?:\.\d*)?)\s*,\s*([+-]?\d+(?:\.\d*)?)\s*$/)
      if (!m) { return; }
      const xyz = [m[1], m[2], m[3]].map(v => parseFloat(v));
      const v3: Geo.Vector3 = { x: xyz[0], y: xyz[1], z: xyz[2] };
      context.emit("input", v3);
    };
    return {
      vector_info,
      format,
      viewParam,
      comp: {
        cv: computed({
          get: () => [prop.value.x, prop.value.y, prop.value.z].join(","),
          set: assimilateVectorStr,
        }),
        str: computed({
          get: () => sprintf("#%02x%02x%02x", prop.value.x, prop.value.y, prop.value.z),
          set: (text: string) => {
            const m = text.match(/^#([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})/);
            if (!m) { return; }
            viewParam.color_text = text;
            const v3: Geo.Vector3 = {
              x: parseInt(m[1], 16),
              y: parseInt(m[2], 16),
              z: parseInt(m[3], 16),
            };
            context.emit("input", v3);
          },
        }),
      },
    };
  },
});

