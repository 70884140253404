import { render, staticRenderFns } from "./Camera.vue?vue&type=template&id=1bb05a26&scoped=true&lang=pug&"
import script from "./Camera.vue?vue&type=script&lang=ts&"
export * from "./Camera.vue?vue&type=script&lang=ts&"
import style0 from "./Camera.vue?vue&type=style&index=0&id=1bb05a26&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb05a26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSimpleTable,VTextField})
