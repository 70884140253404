









import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components: {
  },

  props: {
    value: {
      type: Object as PropType<Geo.Vector3>,
      required: true,
    },
    label: {
      type: String,
    },
    scalar_validator: {
      type: Function as PropType<(val: number) => boolean>,
    },
    vector_validator: {
      type: Function as PropType<(val: Geo.Vector3) => boolean>,
    },
  },

  setup(prop: {
    value: Geo.Vector3;
    scalar_validator?: (val: number) => boolean;
    vector_validator?: (val: Geo.Vector3) => boolean;
  }, context: SetupContext) {
    const viewData = {
      vector_text: "",
    };
    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    const assimilateVectorStr = (e: any) => {
      const str: string = e;
      const m = str.match(/^\s*([+-]?\d+(?:\.\d*)?)\s*,\s*([+-]?\d+(?:\.\d*)?)\s*,\s*([+-]?\d+(?:\.\d*)?)\s*$/)
      if (!m) { return; }
      const xyz = [m[1], m[2], m[3]].map(v => parseFloat(v));
      if (prop.scalar_validator) {
        if (xyz.some(v => !prop.scalar_validator!(v))) { return; }
      }
      const v3: Geo.Vector3 = { x: xyz[0], y: xyz[1], z: xyz[2] };
      if (prop.vector_validator) {
        if (!prop.vector_validator(v3)) { return; }
      }
      context.emit("input", v3);
    };
    const updateVectorText = () => {
      viewData.vector_text = `${prop.value.x},${prop.value.y},${prop.value.z}`;
    };
    updateVectorText();
    watch(() => prop, updateVectorText);

    return {
      format,
      viewData,
      assimilateVectorStr,
    };
  },
});

