





































































































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  props: {
    info1: {
      type: Object as PropType<Geo.Vector3Info>,
      required: true,
    },
    info2: {
      type: Object as PropType<Geo.Vector3Info>,
      required: true,
    },
  },

  setup(prop: {
      info1: Geo.Vector3Info;
      info2: Geo.Vector3Info;
  }, context: SetupContext) {
    const svg_size = 120;

    const relational_info = (() => {
        const dot = computed(() => Geo.dot(prop.info1.vector, prop.info2.vector));
        const cross = computed(() => Geo.cross(prop.info1.vector, prop.info2.vector));
        const cosine = computed(() => dot.value / prop.info1.norm / prop.info2.norm);
        const sine = computed(() => Geo.norm(cross.value) / prop.info1.norm / prop.info2.norm);
        const add = computed(() => Geo.add(prop.info1.vector, prop.info2.vector));
        const sub = computed(() => Geo.sub(prop.info1.vector, prop.info2.vector));
        const largestComponent = computed(() => Math.max(Geo.largestComponent(prop.info1.vector), Geo.largestComponent(prop.info2.vector)));
        return {
          dot,
          cross,
          cross_normal: computed(() => Geo.normalize(cross.value)),
          cosine,
          sine,
          degree: computed(() => Geo.radian2degree(Math.acos(cosine.value))),
          add,
          sub,
          largestComponent,
        }
    })();
    const viewParams = {
      height: svg_size, width: svg_size,
      viewBox: computed(() => {
        const l = Math.max(relational_info.largestComponent.value / 2, 1);
        return `${-svg_size/2} ${-svg_size/2} ${svg_size} ${svg_size}`
      }),
      scaled_v1: computed(() => {
        return Geo.multiply(prop.info1.vector, svg_size / relational_info.largestComponent.value);
      }),
      scaled_v2: computed(() => {
        return Geo.multiply(prop.info2.vector, svg_size / relational_info.largestComponent.value);
      }),
    };

    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    return {
      format,
      relational_info,
      viewParams,
    };
  },
});

