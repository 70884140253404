



















































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import CopyButton from '@/components/CopyButton.vue'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components : {
    CopyButton
  },
  
  props: {
    info1: {
      type: Object as PropType<Geo.Vector3Info>,
      required: true,
    },
    info2: {
      type: Object as PropType<Geo.Vector3Info>,
      required: true,
    },
  },

  setup(prop: {
      info1: Geo.Vector3Info;
      info2: Geo.Vector3Info;
  }, context: SetupContext) {

    const relational_info = (() => {
        const dot = computed(() => Geo.dot(prop.info1.vector, prop.info2.vector));
        const cross = computed(() => Geo.cross(prop.info1.vector, prop.info2.vector));
        const cosine = computed(() => dot.value / prop.info1.norm / prop.info2.norm);
        const sine = computed(() => Geo.norm(cross.value) / prop.info1.norm / prop.info2.norm);
        const add = computed(() => Geo.add(prop.info1.vector, prop.info2.vector));
        const sub = computed(() => Geo.sub(prop.info1.vector, prop.info2.vector));
        return {
          dot,
          cross,
          cross_normal: computed(() => Geo.normalize(cross.value)),
          cosine,
          sine,
          degree: computed(() => Geo.radian2degree(Math.acos(cosine.value))),
          add,
          sub,
        }
    })();
    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    return {
      format,
      relational_info,
    };
  },
});

