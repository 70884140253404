














































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import VectorField from '@/components/lv1/VectorField.vue'
import CopyButton from '@/components/CopyButton.vue'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  components: {
    VectorField, CopyButton,
  },

  props: {
    value: {
      type: Object as PropType<Geo.StraightLine>,
      required: true,
    },
  },

  setup(prop: {
    value: Geo.StraightLine;
  }, context: SetupContext) {

    const viewData = {
      raw_direction: { ...prop.value.direction }
    };

    const format = {
      number_str: Format.number_str,
      vector_str: Format.vector_str,
    };

    const listeners = {
      updatedRawDir: (e: any) => {
        Geo.refine(viewData.raw_direction);
        if (!Geo.isFinite({ ...viewData.raw_direction })) { return; }
        prop.value.direction = Geo.normalize(viewData.raw_direction);
        context.emit("input", prop.value);
      },
    }

    const slinfo = computed(() => {
      Geo.refine(prop.value.origin);
      return Geo.slinfo(prop.value);
    });

    return {
      viewData,
      format,
      listeners,
      slinfo,
      handleInput: (e: any) => context.emit("input", prop.value),
    };
  },
});

