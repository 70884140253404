

























































import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Geo, Format } from '@/models/geo'
import { sprintf } from 'sprintf-js'

export default defineComponent({
  props: {
    camera_origin: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
    camera_direction: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
    viewport_topleft: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
    viewport_topright: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
    viewport_bottomleft: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
    viewport_bottomright: {
      type: Object as PropType<Geo.Vector2>,
      required: true,
    },
  },

  setup(prop: {
    camera_origin: Geo.Vector2;
    camera_direction: Geo.Vector2;
    viewport_topleft: Geo.Vector2;
    viewport_topright: Geo.Vector2;
    viewport_bottomleft: Geo.Vector2;
    viewport_bottomright: Geo.Vector2;
  }, context: SetupContext) {
    const svg_size = 120;
    const svg_span = svg_size + 10;
    const viewParams = computed(() => {
      console.log(prop);
      const viewport_center = _.reduce([
        prop.viewport_topleft,
        prop.viewport_topright,
        prop.viewport_bottomleft,
        prop.viewport_bottomright,
      ], (s, v) => ({ x: s.x + v.x, y: s.y + v.y }), { x: 0, y: 0 });
      viewport_center.x /= 4;
      viewport_center.y /= 4;
      console.log({ viewport_center });
      let relative_topleft = Geo.sub2(prop.viewport_topleft, viewport_center);
      let relative_topright = Geo.sub2(prop.viewport_topright, viewport_center);
      let relative_bottomleft = Geo.sub2(prop.viewport_bottomleft, viewport_center);
      let relative_bottomright = Geo.sub2(prop.viewport_bottomright, viewport_center);
      let relative_origin = Geo.sub2(prop.camera_origin, viewport_center);
      const largestComponent = _([
        relative_topleft,
        relative_topright,
        relative_bottomleft,
        relative_bottomright,
        relative_origin,
      ]).flatMap(v => [v.x, v.y]).map(v => Math.abs(v)).max()!;
      console.log({ largestComponent });
      const l = svg_size / 2 / Math.max(largestComponent, 1);
      relative_topleft = Geo.multiply2(relative_topleft, l);
      relative_topright = Geo.multiply2(relative_topright, l);
      relative_bottomleft = Geo.multiply2(relative_bottomleft, l);
      relative_bottomright = Geo.multiply2(relative_bottomright, l);
      relative_origin = Geo.multiply2(relative_origin, l);
      const direction_topleft = Geo.sub2(relative_topleft, relative_origin);
      const direction_topright = Geo.sub2(relative_topright, relative_origin);
      const direction_bottomleft = Geo.sub2(relative_bottomleft, relative_origin);
      const direction_bottomright = Geo.sub2(relative_bottomright, relative_origin);
      return {
        viewBox: `${-svg_span/2} ${-svg_span/2} ${svg_span} ${svg_span}`,
        svg_size,
        relative_topleft,
        relative_topright,
        relative_bottomleft,
        relative_bottomright,
        relative_origin,
        direction_topleft,
        direction_topright,
        direction_bottomleft,
        direction_bottomright,
      };
    });
    return {
      viewParams,
    };
  },
});

